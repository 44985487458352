import { Admin, Resource, CustomRoutes } from "react-admin";
import Layout from "./components/Layout/Layout";
// import {
//   FirebaseAuthProvider,
//   FirebaseDataProvider,
// } from "react-admin-firebase";

import Login from "./components/MockedLogin/MockedLogin";
import { Route, BrowserRouter } from "react-router-dom";
//import SelectTeam from "./components/Team/SelectTeam";
//import TeamCreate from "./components/Team/TeamCreate";
//import TeamEdit from "./components/Team/TeamEdit";
import MembersList from "./components/Members/MembersList";
import MembersShow from "./components/Members/MembersShow";
import GarageList from "./components/Garage/GarageList";
import GarageShow from "./components/Garage/GarageShow";
import Mileage from "./components/Garage/Mileage/Mileage";
import EngineSystem from "./components/Garage/Mileage/EngineSystem";
import Transmission from "./components/Garage/Mileage/Transmission";
import Chassis from "./components/Garage/Mileage/Chassis";
import Brakes from "./components/Garage/Mileage/Brakes";
import Suspensions from "./components/Garage/Mileage/Suspensions";
import Fia from "./components/Garage/Mileage/Fia";
import Purchase from "./components/Garage/Purchase/Purchase";
import Insurance from "./components/Garage/Insurance/Insurance";
import Passport from "./components/Garage/Passport/Passport";
import Tech from "./components/Garage/Tech/Tech";
import Testbench from "./components/Garage/Testbench/Testbench";
import TyresList from "./components/Garage/TyresDiary/TyresList";
import TyresShow from "./components/Garage/TyresDiary/TyresShow";
import InventoryList from "./components/Inventory/InventoryList";
import InventoryShow from "./components/Inventory/InventoryShow";
import EventList from "./components/Events/EventsList";
import EventShow from "./components/Events/EventsShow/EventsShow";
import EventsSession from "./components/Events/EventsShow/Session/Session";
import VechicleSetup from "./components/Events/EventsShow/Session/VechicleSetup/VechicleSetup";
import Run from "./components/Events/EventsShow/Session/Run/Run";
import DriverReport from "./components/Events/EventsShow/Session/DriverReport/DriverReport";
import TelemetryNotes from "./components/Events/EventsShow/Session/TelemetryNotes/TelemetryNotes";
import TyresPressure from "./components/Events/EventsShow/Session/TyresPressure/TyresPressure";

//import UserCreate from "./components/User/UserCreate";
// import UserEdit from "./components/User/UserEdit";
// import UserList from "./components/User/UserList";
//import UserShow from "./components/User/UserShow";
import Welcome from "./components/Welcome/Welcome";
import fakeDataProvider from "ra-data-fakerest";
import authProvider from "./config/authProvider";
import mockedData from "./data/mocked.json";

import { theme } from "./theme";
import GPSLogger from "./components/GPSLogger/GPSLogger";
import { Livedata } from "./components/Livedata/Livedata";
//import { firebaseConfig } from "./config/firebase";

// import EventMonitor from "./EventMonitor/EventMonitor";

//const options = { logging: true };mockedData
const dataProvider = fakeDataProvider(mockedData);
//const dataProvider = FirebaseDataProvider(firebaseConfig, options);
//const authProvider = FirebaseAuthProvider(firebaseConfig, options);

// const theme = createTheme({
//   palette: {
//     type: dark,
//   },
// });

// const customRoutes = [
//   <Route exact path="/select-team" element={<SelectTeam />} />,
//   <Route noLayout exact path="/welcome-page" element={<Welcome />} />,

//   // <RouteWithoutLayout exact path="/register" component={Register} />,
// ];

const App = () => (
  <BrowserRouter>
    <Admin
      layout={Layout}
      title={"Racing Teams Login"}
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <CustomRoutes>
        <Route exact path={`/garage/:id/show/tyres`} element={<TyresList />} />
        <Route
          exact
          path={`/garage/:id/show/tyres/:id/show`}
          element={<TyresShow />}
        />
        <Route exact path={`/garage/:id/show/mileage`} element={<Mileage />} />
        <Route
          exact
          path={`/garage/:id/show/mileage/engine-system`}
          element={<EngineSystem />}
        />
        <Route
          exact
          path={`/garage/:id/show/mileage/transmission-system`}
          element={<Transmission />}
        />
        <Route
          exact
          path={`/garage/:id/show/mileage/chassis-system`}
          element={<Chassis />}
        />
        <Route
          exact
          path={`/garage/:id/show/mileage/brakes-system`}
          element={<Brakes />}
        />
        ,
        <Route
          exact
          path={`/garage/:id/show/mileage/suspensions`}
          element={<Suspensions />}
        />
        <Route exact path={`/garage/:id/show/mileage/fia`} element={<Fia />} />
        <Route
          exact
          path={`/garage/:id/show/purchase`}
          element={<Purchase />}
        />
        <Route
          exact
          path={`/garage/:id/show/insurance`}
          element={<Insurance />}
        />
        <Route
          exact
          path={`/garage/:id/show/passport`}
          element={<Passport />}
        />
        <Route exact path={`/garage/:id/show/tech`} element={<Tech />} />
        <Route
          exact
          path={`/garage/:id/show/testbench`}
          element={<Testbench />}
        />
        <Route
          exact
          path={`/events/:id/show/session`}
          element={<EventsSession />}
        />
        <Route
          exact
          path={`/events/:id/show/session/vechicle-setup/:sessionId`}
          element={<VechicleSetup />}
        />
        <Route
          exact
          path={`/events/:id/show/session/run/:sessionId`}
          element={<Run />}
        />
        <Route
          exact
          path={`/events/:id/show/session/driver-report/:programId`}
          element={<DriverReport />}
        />
        <Route
          exact
          path={`/events/:id/show/session/telemetry-notes/:programId`}
          element={<TelemetryNotes />}
        />
        <Route
          exact
          path={`/events/:id/show/session/tyre-pressure/:sessionId`}
          element={<TyresPressure />}
        />
      </CustomRoutes>

      <CustomRoutes noLayout>
        <Route exact path="/welcome-page" element={<Welcome />} />,
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route exact path="/gps" element={<GPSLogger />} />,
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route exact path="/livedata" element={<Livedata />} />,
      </CustomRoutes>

      <Resource name="members" list={MembersList} show={MembersShow} />
      <Resource name="garage" list={GarageList} show={GarageShow} />
      <Resource name="inventory" list={InventoryList} show={InventoryShow} />
      <Resource name="events" list={EventList} show={EventShow} />
      {/*  <Resource
        name="users"
        list={UserList}
        // show={UserShow}
        edit={UserEdit}
        create={UserCreate}
      /> */}
    </Admin>
    {/* <EventMonitor /> */}
  </BrowserRouter>
);

export default App;
