import React, { useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Button, InputLabel, TextField, Typography } from "@mui/material";

export const Livedata = () => {
  const [url, setUrl] = useState(
    "https://livedatamisano.perugiatiming.com/livetiming"
  );
  const [transito, setTransito] = useState();
  const [manifestazioneId, setmanifestazioneId] = useState("");
  const [garaId, setGaraId] = useState("");
  const [sessioneId, setSessioneId] = useState("");

  const handleConnect = () => {
    if (!url || !manifestazioneId || !garaId || !sessioneId) {
      return;
    }

    const connection = new HubConnectionBuilder().withUrl(url).build();

    const receivedTransit = (t) => {
      setTransito(t);
    };

    connection.on("BroadcastTransito", receivedTransit);
    connection.on("Error", (err) => console.error(err));
    connection.onclose(() => console.log("Connection closed"));

    connection
      .start()
      .then(() => {
        // add to group
        const manifestazioneId = "a1e07a75-7058-4604-b528-9d17a490a02e";
        const garaId = 1;
        const sessioneId = "L2B";
        const groupKey = `${manifestazioneId}_${garaId}_${sessioneId}`;
        return connection.invoke("AddToGroup", groupKey);
      })
      .then(() => {
        console.log(
          "Connessione al web socket avviata e iscrizione al gruppo completata."
        );
      })
      .catch(function (err) {
        console.error("error", err.toString());
      });
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <Typography color="primary" variant="body">
        {`// CONTENITORI 
        // 1 WorldSBK 
        // 2 CIV 
        // 5 Aci Sport 
        // 6 Magione 
        // 7 Misano 
        // TUTTE LE MANIFESTAZIONI 
        // https://livedataacisport.perugiatiming.com/api/manifestazione/{CONTENITORE_ID}
        // TUTTE LE SESSIONI (al suo interno ci sono i dati per comporre l'url del websocket) 
        // https://livedataacisport.perugiatiming.com/api/sessione/manifestazioneId`}
      </Typography>
      <br />
      <InputLabel>SignalR URL</InputLabel>
      <TextField
        value={url}
        sx={{ width: 600 }}
        required
        name="url"
        onChange={(e) => setUrl(e.target.value)}
      ></TextField>
      <InputLabel>Manifestazione</InputLabel>
      <TextField
        sx={{ width: 600 }}
        required
        name="manifestazioneId"
        onChange={(e) => setmanifestazioneId(e.target.value)}
      ></TextField>
      <InputLabel>Gara</InputLabel>
      <TextField
        sx={{ width: 600 }}
        required
        name="garaid"
        onChange={(e) => setGaraId(e.target.value)}
      ></TextField>
      <InputLabel>Sessione</InputLabel>
      <TextField
        sx={{ width: 600 }}
        required
        name="sessioneId"
        onChange={(e) => setSessioneId(e.target.value)}
      ></TextField>
      <Button
        sx={{ width: "120px", display: "block", marginY: "20px" }}
        disabled={!url}
        variant="contained"
        onClick={handleConnect}
      >
        Connect
      </Button>
      <br />
      {transito && (
        <>
          <InputLabel>Transito:</InputLabel>
          <Typography color="primary" variant="body1">
            {JSON.stringify(transito)}
          </Typography>
        </>
      )}{" "}
    </div>
  );
};
