import { useState, useEffect } from "react";
import {
  Button,
  Box,
  FormControl,
  MenuItem,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import Logo from "../../assets/images/logo.png";
import { useLogin, useNotify, useRedirect, useGetList } from "react-admin";
import { MuiOtpInput } from "mui-one-time-password-input";
import useCheckAccessCode from "../../hooks/useCheckAccessCode";

const selectActiveStyle = {
  "& .MuiSelect-select": {
    color: "#000",
    fontWeight: "bold",
  },
  backgroundColor: "#fff",
  fontWeight: "bold",
  "& .MuiSelect-icon": {
    color: "#000",
  },
};

const MockedLogin = () => {
  const { data } = useGetList("members");
  const [membersData, setMembersData] = useState(null);
  const [value, setValue] = useState(
    JSON.stringify({
      id: "Select User",
      name: "Select User",
      role: "Select User",
    })
  );
  const [username, setUsername] = useState("Select User");
  const [code, setCode] = useState(localStorage.getItem("code") || "");
  const [isValidCode, setIsValidCode] = useState(false);

  const [validateAccessCodeRequest, loading] = useCheckAccessCode();

  const notify = useNotify();
  const login = useLogin();
  const redirect = useRedirect();

  useEffect(() => {
    const getData = async () => {
      const response = await data;
      setMembersData(response);
    };

    getData();
  });

  useEffect(() => {
    if (code) {
      handleOnInputCodeComplete(code, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
    setUsername(JSON.parse(e.target.value).name);
    localStorage.setItem("role", JSON.parse(e.target.value).role);
    localStorage.setItem("memberId", JSON.parse(e.target.value).id);
  };

  const handleLogin = async () => {
    try {
      await login({ username, code });
      redirect("/welcome-page");
    } catch (err) {
      notify("Invalid email or password");
    }
  };

  const handleOnInputCodeComplete = async (newValue, fromStorage = false) => {
    const data = await validateAccessCodeRequest({ code: newValue });
    if (data) {
      notify(`The access-code expires in ${data.data.expDays} days`, {
        type: "info",
      });
      setIsValidCode(true);
      localStorage.setItem("code", data.data.code);
      return;
    }
    if (fromStorage) {
      localStorage.removeItem("code");
      setCode("");
    } else {
      notify("Invalid code!", { type: "error" });
    }
    if (process.env.NODE_ENV === "development") setIsValidCode(true);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        overflow: "hidden",
        height: "100vh",
        // paddingLeft: "60px",
      }}
    >
      <Grid container direction="row" height="100vh">
        {/* <Grid xs={0.5} item></Grid> */}

        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          xs
          zeroMinWidth
        >
          <Grid
            container
            item
            xs={6}
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              <img
                alt=""
                src={Logo}
                style={{
                  width: "200px",
                }}
              />
            </Grid>

            <Grid item>
              {isValidCode ? (
                <FormControl sx={{ m: 1, width: 300 }}>
                  <TextField
                    select
                    id={username}
                    variant="outlined"
                    value={value}
                    onChange={handleChange}
                    sx={username !== "Select User" ? selectActiveStyle : {}}
                  >
                    <MenuItem
                      key="Select User"
                      disabled
                      value={JSON.stringify({
                        id: "Select User",
                        name: "Select User",
                        role: "Select User",
                      })}
                    >
                      Select User
                    </MenuItem>
                    {membersData?.length &&
                      membersData.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={JSON.stringify({
                            id: item.id,
                            name: item.name,
                            role: item.role,
                          })}
                        >{`${item.name} (${item.role})`}</MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              ) : (
                <>
                  <FormControl
                    sx={{
                      m: 1,
                      maxWidth: 400,
                      minWidth: 260,
                    }}
                  >
                    <MuiOtpInput
                      display="flex"
                      gap={1}
                      TextFieldsProps={{
                        size: "medium",
                        placeholder: "-",
                        autoFocus: false,
                        disabled: loading,
                        inputProps: {
                          autoComplete: "off",
                          style: { textTransform: "uppercase" },
                        },
                      }}
                      sx={{
                        ".MuiOtpInput-TextField": {
                          [`& fieldset`]: {
                            borderRadius: "6px",
                          },
                        },
                      }}
                      value={code}
                      onChange={setCode}
                      onComplete={handleOnInputCodeComplete}
                      length={6}
                    />
                  </FormControl>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={6} justifyContent="center" alignItems="center">
            {!isValidCode && (
              <Typography
                variant="body2"
                sx={{ fontSize: "18px", fontWeight: "bold", my: "15px" }}
                color="primary"
                align="center"
              >
                {!loading ? "Enter your code to access the Demo" : "Loading..."}
              </Typography>
            )}
            {username === "Select User" ? null : (
              <Button
                sx={{ width: "160px!important" }}
                variant="contained"
                onClick={handleLogin}
                color="primary"
              >
                Enter
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MockedLogin;
